import React, {createContext, useState} from "react";
import {isBrowser} from "../helpers/helpers";


const UserContext = createContext(undefined);


function UserProvider({children}) {
  function getCountryCodeFromLocalStorage() {
    if (isBrowser()) {
      const countryCodeFromLocalStorage = localStorage.getItem("countryCode") ? localStorage.getItem("countryCode") : "BE";
      return countryCodeFromLocalStorage;
    }
    else{
      return "BE"
    }
  }

  const [countryCode, setCountryCode] = useState(getCountryCodeFromLocalStorage());

  return (
    <UserContext.Provider value={{countryCode, setCountryCode}}>
        {children}
    </UserContext.Provider>
  );
}

export {UserProvider, UserContext};
