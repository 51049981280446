exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-app-app-js": () => import("./../../../src/pages/app/app.js" /* webpackChunkName: "component---src-pages-app-app-js" */),
  "component---src-pages-cookie-policy-js": () => import("./../../../src/pages/cookie-policy.js" /* webpackChunkName: "component---src-pages-cookie-policy-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-kledij-js": () => import("./../../../src/pages/kledij.js" /* webpackChunkName: "component---src-pages-kledij-js" */),
  "component---src-pages-products-js": () => import("./../../../src/pages/products.js" /* webpackChunkName: "component---src-pages-products-js" */),
  "component---src-pages-terms-and-conditions-js": () => import("./../../../src/pages/terms-and-conditions.js" /* webpackChunkName: "component---src-pages-terms-and-conditions-js" */),
  "component---src-pages-test-racket-js": () => import("./../../../src/pages/test-racket.js" /* webpackChunkName: "component---src-pages-test-racket-js" */),
  "component---src-pages-verzending-retour-js": () => import("./../../../src/pages/verzending-retour.js" /* webpackChunkName: "component---src-pages-verzending-retour-js" */),
  "component---src-templates-clothing-clothing-item-detail-page-jsx": () => import("./../../../src/templates/clothing/clothing-item-detail-page.jsx" /* webpackChunkName: "component---src-templates-clothing-clothing-item-detail-page-jsx" */),
  "component---src-templates-material-brand-list-page-jsx": () => import("./../../../src/templates/material/brand-list-page.jsx" /* webpackChunkName: "component---src-templates-material-brand-list-page-jsx" */),
  "component---src-templates-material-material-detail-page-jsx": () => import("./../../../src/templates/material/material-detail-page.jsx" /* webpackChunkName: "component---src-templates-material-material-detail-page-jsx" */),
  "component---src-templates-material-material-list-page-jsx": () => import("./../../../src/templates/material/material-list-page.jsx" /* webpackChunkName: "component---src-templates-material-material-list-page-jsx" */)
}

