import React from "react"
import { CartProvider } from "react-use-cart";
import { UserProvider } from "./src/context/UserContext";

export const wrapRootElement = ({ element }) => (
       <UserProvider>
              <CartProvider>
                     {element}
              </CartProvider>
       </UserProvider>
)

