const currencyFormatter = new Intl.NumberFormat('sfb', {
    style: 'currency',
    currency: 'EUR',
    minimumFractionDigits: 2
})

export const formatCurrency = (price) => {
    return currencyFormatter.format(price)
}

export const calculateDiscount = (total, voucher, showNegativeValue) => {
    const { discountPercentage, couponType, absoluteDiscount } = voucher
    var discount = 0

    switch (couponType) {
        case "Percentage":
            discount = total * discountPercentage / 100
            break;
        case "Giftcard":
            discount = absoluteDiscount >= total ? total : absoluteDiscount
            break;
        case "Absolute":
            discount = absoluteDiscount >= total ? total : absoluteDiscount
            break;
        case "Free":
            // to implement
            break;
        default:
            console.log("")
    }
    return showNegativeValue ? -discount : discount
}
export const containsPhysicalProduct = orderLines => {
    const found = orderLines.find(line => "Cadeaubon" !== line.productType)
    return found ? true : false
}

export const getShippingCost = (items, settings, countryCode) => {
    const { shippingCostBe, shippingCostNl, shippingCostFr } = settings
    const minOrderQuantity = getMinOrderValue(settings, countryCode)
    let shippingCost

    switch (countryCode) {
        case "BE":
            shippingCost = shippingCostBe
            break;
        case "NL":
            shippingCost = shippingCostNl
            break;
        case "FR":
            shippingCost = shippingCostFr
            break;
        default:
            shippingCost = Number(process.env.GATSBY_SHIPPING_COST);
    }

    const cartTotal = getTotalWithoutDigitalItems(items)
    return cartTotal <= minOrderQuantity ? shippingCost : 0
}

export const getTotalWithoutDigitalItems = orderLines => {
    const linesWithoutDigitalItems = orderLines.filter(line => "Cadeaubon" !== line.productType)
    let total = 0
    linesWithoutDigitalItems.forEach((line) => {
        total += line.price * line.quantity
    });
    return Number(total).toFixed(2);
}

export const getMinOrderValue = (settings, countryCode) => {

    const { minOrderValueBe, minOrderValueNl, minOrderValueFr } = settings
    let minOrderQuantity

    switch (countryCode) {
        case "BE":
            minOrderQuantity = minOrderValueBe
            break;
        case "NL":
            minOrderQuantity = minOrderValueNl
            break;
        case "FR":
            minOrderQuantity = minOrderValueFr
            break;
        default:
            minOrderQuantity = Number(process.env.GATSBY_SHIPPING_MIN_AMOUNT)
    }
    return minOrderQuantity
}

export const shippingNeeded = (lines) => {
    return containsPhysicalProduct(lines);
}


export const calculateTotal = (total, items, voucher, settings, countryCode) => {
    const discountAmount = calculateDiscount(total, voucher, false)
    let amount = total
    if (shippingNeeded(items)) {
        amount += getShippingCost(items, settings, countryCode)
    }
    if (discountAmount && discountAmount > 0 && !isNaN(discountAmount)) {
        amount -= discountAmount
    }
    return amount
}


export const isProduction = () => {
    return process.env.GATSBY_ENV === "PRD" ? true : false
}

export const isLocal = () => {
    return process.env.GATSBY_ENV === "LOCAL" ? true : false
}

export const isTest = () => {
    return process.env.GATSBY_ENV === "TST" ? true : false
}

export const isBrowser = () => typeof window !== "undefined"

